import React from "react";
import { Box } from "rebass/styled-components";
import Layout from "../components/Layout";
import Container from "../components/UI/Container";
import { useStaticQuery, graphql } from "gatsby";
import Markdown from "../components/UI/Markdown";

const Terms = props => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { title: { eq: "legals" } }) {
          frontmatter {
            name
            url
            address
            publication
            url
            mail
            phone
            terms
          }
        }
      }
    `
  );
  const legals = markdownRemark.frontmatter || {};
  return (
    <Layout title="Terms of use & IP" slug="terms" {...props}>
      <Box py={10}>
        <Container>
          <Markdown>{legals.terms}</Markdown>
        </Container>
      </Box>
    </Layout>
  );
};

export default Terms;
